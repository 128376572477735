import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Head from '../components/head';

import styles from './listpage.module.scss';

const HomePage = ({
  data: {
    allContentfulVehicle: { nodes: allVehicles },
  },
  pageContext: { ordering },
}) => {
  let orderedVehicles = [];

  let inOrder = [];

  ordering.forEach((thisPlate, i) => {
    const match = allVehicles.filter(
      ({ numberPlate }) => thisPlate === numberPlate
    );

    if (match) {
      inOrder.push(...match);
    }

    if (i + 1 >= ordering.length) {
      orderedVehicles = inOrder;
    }
  });

  return (
    <Layout>
      <Head title="Hollingdean Parking Gallery" />

      <main className={styles.listpage}>
        <ul className={styles.grid}>
          {orderedVehicles.map(
            (
              {
                make,
                model,
                colour,
                numberPlate,
                gridImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
              },
              i
            ) => (
              <li key={`allVehicles-${i}`} className={styles.item}>
                <Link
                  to={`/gallery/${numberPlate
                    .replace(' ', '-')
                    .toLowerCase()}/`}
                  className={styles.link}
                  aria-label={`View ${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={`${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                    placholder="dominantColor"
                    loading="lazy"
                  />
                  <h2 className={styles.numberplate}>{numberPlate}</h2>
                </Link>
              </li>
            )
          )}
        </ul>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query galleryPage {
    allContentfulVehicle {
      nodes {
        id
        numberPlate
        colour
        make
        model
        gridImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 40
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
